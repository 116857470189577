import React, { useMemo } from "react";
import { SanityImageCrop, SanityImageHotspot } from "../sanity-schema";
import { ImageMap } from "../client";
import imageUrlBuilder from "@sanity/image-url";
import { Image, ImageProps } from "@chakra-ui/react";

const builder = imageUrlBuilder({
  projectId: "om0huuvj",
  dataset: "production",
});

export const SanityImage: React.FC<
  {
    image: {
      asset?: {
        _ref: string;
        _type: "reference";
      };
      hotspot?: SanityImageHotspot;
      crop?: SanityImageCrop;
      _type: "image";
    };
  } & ImageProps
> = ({ image, ...props }) => {
  const url = useMemo(() => {
    const ref = image.asset?._ref;
    if (!ref) {
      return null;
    }
    const img = builder.image(image).width(1200);
    return img.url();
  }, [image]);

  if (!url) {
    return null;
  }

  return <Image {...props} src={url} alt="" />;
};
