import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { SanityImage } from "./SanityImage";
import { SanityText } from "./SanityText";
import { AllActivePopupsResult } from "../sanity-schema";
import { useEffect } from "react";
import { POPUP_SEEN_COOKIE_NAME } from "../popup-middleware";

export const SanityPopup: React.FC<{
  popup: AllActivePopupsResult[number] | null | undefined;
}> = ({ popup }) => {
  const { isOpen, onOpen, onClose } = useDisclosure({
    onClose() {
      document.cookie = document.cookie
        .split(";")
        .filter((s) => s.trim().startsWith(POPUP_SEEN_COOKIE_NAME))
        .concat(`${POPUP_SEEN_COOKIE_NAME}=${new Date().getTime()}`)
        .join(";");
    },
  });
  useEffect(() => {
    const t = setTimeout(
      () => {
        onOpen();
      },
      (popup?.dwellTime ?? 0) * 1000
    );
    return () => clearTimeout(t);
  }, [popup, onOpen]);
  if (!popup) {
    return null;
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" closeOnOverlayClick>
      <ModalOverlay />
      <ModalContent mt="10vh">
        <ModalCloseButton bg="white" />
        <SanityImage
          image={popup.image!}
          roundedTop="6px"
          height="260px"
          objectFit="cover"
        />
        <ModalBody>
          <SanityText content={popup.content} />
        </ModalBody>

        <ModalFooter flexDir="row" justifyContent="center">
          <Button
            as="a"
            background="#8DEBCF"
            color="black"
            w="full"
            href={popup.button?.link ?? "#"}
          >
            {popup.button?.text}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
