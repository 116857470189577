import { PortableText, PortableTextBlock } from "@portabletext/react";
import { Heading, Text } from "@chakra-ui/react";
import { ComponentProps } from "react";

export const SanityText: React.FC<{
  content: ComponentProps<typeof PortableText>["value"] | null | undefined;
}> = ({ content }) => {
  return (
    <PortableText
      value={content ?? []}
      components={{
        block: {
          normal: ({ children }) => <Text minH="1lh">{children}</Text>,
          h1: ({ children }) => <Heading as="h1">{children}</Heading>,
          h2: ({ children }) => <Heading as="h2">{children}</Heading>,
          h3: ({ children }) => <Heading as="h3">{children}</Heading>,
          h4: ({ children }) => <Heading as="h4">{children}</Heading>,
          h5: ({ children }) => <Heading as="h5">{children}</Heading>,
          h6: ({ children }) => <Heading as="h6">{children}</Heading>,
        },
      }}
    />
  );
};
