import { createClient } from "@sanity/client";

export const createSanityClient = (preview: boolean) =>
  createClient({
    projectId: "om0huuvj",
    dataset: "production",
    useCdn: !preview,
    apiVersion: "v2022-03-07",
    perspective: preview ? "previewDrafts" : "published",
    token: process.env.SANITY_API_TOKEN,
  });

export const d = <T>(data: T | null | undefined, defaultValue: T): T => {
  return data ?? defaultValue;
};

export type ImageMap = Map<string, { url: string }>;
