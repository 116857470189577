import { defineQuery } from "groq";

export const pageBySlug = defineQuery(`
  *[_type == "page" && path == $path][0]
`);

export const popupById = defineQuery(`
  *[_type == "popup" && _id == $id][0]
`);

export const allActivePopups = defineQuery(`
  *[_type == "popup" && enabled == true && (!defined(startDate) || startDate < now()) && (!defined(endDate) || endDate > now())]
`);
