import type { AppProps } from "next/app";
import { extendTheme } from "@chakra-ui/react";
import { ChakraProvider } from "@chakra-ui/provider";
import { ApolloProvider, SuspenseCache } from "@apollo/client";
import { createApolloClient } from "@/util/apollo-client";
import { Suspense, useMemo } from "react";
import { Poppins } from "next/font/google";
import FacebookPixel from "@/components/FacebookPixel";
import { PodiumScript } from "@/components/PodiumScript";
import { SanityPopup } from "@/sanity/components/SanityPopup";

const suspenseCache = new SuspenseCache();

const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const poppins = Poppins({
  subsets: ["latin"],
  weight: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
});

const theme = extendTheme({
  config,
  fonts: {
    heading: `${poppins.style.fontFamily},sans-serif`,
    body: `${poppins.style.fontFamily},sans-serif`,
  },
});

export default function App({ Component, pageProps }: AppProps) {
  const client = useMemo(() => {
    return createApolloClient();
  }, []);

  return (
    <ApolloProvider client={client} suspenseCache={suspenseCache}>
      <ChakraProvider
        theme={theme}
        colorModeManager={{
          get: () => "light",
          set: () => {},
          type: "localStorage",
        }}
      >
        <style jsx global>{`
          html {
            font-family: ${poppins.style.fontFamily};
          }
        `}</style>
        <Suspense fallback={"Loading..."}>
          <Component {...pageProps} />
        </Suspense>
        <FacebookPixel />
        <PodiumScript />
        <SanityPopup popup={pageProps.popup} />
      </ChakraProvider>
    </ApolloProvider>
  );
}
