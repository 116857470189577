import {
  GetServerSideProps,
  GetServerSidePropsContext,
  GetServerSidePropsResult,
} from "next";
import { createSanityClient } from "./client";
import { allActivePopups, popupById } from "./queries";
import { b } from "@/b";
import { createApolloClient } from "@/util/apollo-client";
import { addCookieToResponse } from "@/util/cookie";
import { AllActivePopupsResult } from "./sanity-schema";

export const POPUP_SEEN_COOKIE_NAME = "vm-popup-last-seen";
export const POPUP_FREQUENCY = 1000 * 60 * 60 * 24 * 7; // 1 week
export const PAGE_VIEW_COOKIE_NAME = "vm-page-views";

const PopupQuery = b.query("Popup", (b) => [b.viewer((b) => [b.id()])]);

export const popupMiddleware = <T>(
  getServerSideProps: (
    ctx: GetServerSidePropsContext
  ) => Promise<GetServerSidePropsResult<T>>
): GetServerSideProps<
  T & {
    popup?: AllActivePopupsResult[number];
  }
> => {
  return (async (ctx: GetServerSidePropsContext) => {
    const originalResult = await getServerSideProps(ctx);

    if (!("props" in originalResult)) {
      return originalResult;
    }

    const lastSeen = new Date();
    lastSeen.setTime(parseInt(ctx.req.cookies[POPUP_SEEN_COOKIE_NAME] ?? "0"));
    if (lastSeen.getTime() + POPUP_FREQUENCY > Date.now()) {
      return originalResult;
    }

    const originalProps = await originalResult.props;

    let pageViews = parseInt(ctx.req.cookies[PAGE_VIEW_COOKIE_NAME] ?? "0");
    pageViews += 1;
    addCookieToResponse(
      ctx.res,
      `${PAGE_VIEW_COOKIE_NAME}=${pageViews}; Path=/; Max-Age=31536000; SameSite=Strict`
    );

    const client = createSanityClient(false);
    const popups = await client.fetch(allActivePopups);

    const priority = {
      critical: 1,
      high: 2,
      medium: 3,
      low: 4,
    };

    popups.sort(
      (a, b) => priority[a.priority ?? "low"] - priority[b.priority ?? "low"]
    );

    const currentUrl = ctx.resolvedUrl;
    const loggedIn = await createApolloClient(ctx.req.headers.cookie)
      .query({
        query: PopupQuery.document(),
      })
      .then((res) => res.data.viewer !== null)
      .catch(() => false);

    const validPopups = popups.filter((popup) => {
      for (const condition of popup.conditions ?? []) {
        if (condition._type === "loggedIn") {
          if (condition.loggedIn && !loggedIn) {
            return false;
          }
          if (!condition.loggedIn && loggedIn) {
            return false;
          }
        }
        if (condition._type === "pageFilter") {
          const included = condition.include?.some((page) => {
            if (page.endsWith("*")) {
              return currentUrl.startsWith(page.slice(0, -1));
            }
            return currentUrl === page;
          });
          if (!included) {
            return false;
          }
          const excluded = condition.exclude?.some((page) => {
            if (page.endsWith("*")) {
              return currentUrl.startsWith(page.slice(0, -1));
            }
            return currentUrl === page;
          });
          if (excluded) {
            return false;
          }
        }
        if (condition._type === "pageViews") {
          if (
            condition.operator === "greater than" &&
            !(pageViews > (condition.views ?? 0))
          ) {
            return false;
          }
          if (
            condition.operator === "less than" &&
            !(pageViews < (condition.views ?? 0))
          ) {
            return false;
          }
        }
      }
      return true;
    });

    const popup = validPopups.at(0) ?? null;

    return {
      props: {
        ...originalProps,
        popup,
      },
    };
  }) as any;
};
